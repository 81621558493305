import React from 'react';

class Weather extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            isLoaded: false
        }

        this.fetchWeatherData();
    }

    async fetchWeatherData(){
        const url = "http://api.drolli.fi/home/weather/Kaarina";

        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    this.weatherData = result;

                    this.setState({
                        isLoaded: true,
                        weatherData: result
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

    }

    render() {


        if(this.state.isLoaded)
        {
            console.log("Fetching weather data\n > http://api.drolli.fi/home/weather/Kaarina");

            return (
                <div className="dashboard_element">
                    <div className="dashboard_element_title blue">Säätilanne</div>  
                    <div className="dashboard_element_line">
                        <div className="col2">
                            <span className="weather_city">Kaarina</span>
                            <div className="weather_extra">
                                Tuulen nopeus {this.weatherData.windspeed} m/s<br/>
                                Kosteus {this.weatherData.humidity}%<br/> 
                                {this.weatherData.forecast}
                            </div>
                        </div>

                        <div className="col2">
                            <span className="weather_temp">{this.weatherData.temperature}&#8451;</span>
                            <img className="weather_icon" src={"http://openweathermap.org/img/w/" + this.weatherData.icon + ".png"} alt="" />
                        </div>
                    </div>
                </div>
            );
        }
        else return ("");
    }
  
}

export default Weather;
