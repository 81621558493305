import React from 'react';
import RoomClimateItem from './RoomClimateItem';

class RoomClimate extends React.Component {

   
    constructor(props)
    {
        super(props);

        this.macs = ['C6:96:F9:8A:39:14', 
                     'EA:0E:6A:AE:CD:81',
                     'E3:F6:CE:64:9E:E2',
                     'FD:B0:09:7A:06:6A', 
                     'ED:FB:6B:1B:F9:97']

        this.rooms = [
                    {room: 'office', room_name: "Työhuone"},
                    {room: 'bedroom', room_name: "Makuuhuone"},
                    {room: 'livingroom', room_name: "Olohuone"},
                    {room: 'kitchen', room_name: "Keittiö"},
                    {room: 'sauna', room_name: "Sauna"}
                    ];
                   /*   'sauna',
                      'livingroom', 
                      'kitchen']*/

        this.dataOutput = "";
        
    }

    

    

    render() {
        return (
            <div className="dashboard_element">
                <div className="dashboard_element_title">Asunnon lämpötila ja kosteus</div>  
               
                {this.rooms.map((item, key) =>
                    <RoomClimateItem key={key}  room={item.room} room_name={item.room_name} />
                )}
                
                
            </div>
          );
    }
  
}

export default RoomClimate;
