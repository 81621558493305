import React from 'react';

class RoomClimateItem extends React.Component {
    
    constructor(props)
    {
        super(props);

        this.room = props.room;
        this.room_name = props.room_name;

        this.state = {
            isLoaded: false,
            data: {}
        }

        this.fetchClimateData(this.room);
    }

    componentDidMount()
    {
        this.interval = setInterval(() => this.fetchClimateData(), 60000); // minuutin välein
    }

    async fetchClimateData(room){
        if (typeof room == 'undefined') return false;
        
        
        const url = "http://api.drolli.fi/home/sensors/" + room;
        console.log("Fetching data for " + room + "\n > "+ url);

        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    this.data = result;

                    this.setState({
                        isLoaded: true,
                        data: result
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

    }


    render() {
        if(this.state.isLoaded)
        {

            return (
                <div className="dashboard_element_line">   
                        <div className="col3">{this.room_name}</div>
                        <div className="col3">{this.state.data.temperature}&#8451;</div>    
                        <div className="col3">{this.state.data.humidity}%</div> 
                </div>  
            );
        }
        else return ("");
    }
  
}

export default RoomClimateItem;
