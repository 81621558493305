import React from 'react';
import './App.css';
import RoomClimate from './components/RoomClimate';
import Weather from './components/Weather';

function App() {
  return (
    <div className="App">
   
      <RoomClimate />

      <Weather />

    </div>
  );
}

export default App;
